@import-normalize ;

@import 'tailwind-base.css';

html {
    font-size: 62.5%;
    font-family: Muli, Roboto, Helvetica Neue, Arial, sans-serif;
    background-color: #262933;
}

body {
    font-size: 14px;
    line-height: 1.4;
}

html, body, #root {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
    margin: 0;
}

h1, .h1 {
    font-size: 24px;
}

h2, .h2 {
    font-size: 20px;
}

h3, .h3 {
    font-size: 16px;
}

h4, .h4 {
    font-size: 15px;
}

h5, .h5 {
    font-size: 13px;
}

h6, .h6 {
    font-size: 12px;
}

.ps > .ps__rail-y,
.ps > .ps__rail-x {
    z-index: 99;
}

a[role=button] {
    text-decoration: none;
}

[role="tooltip"] {
    z-index: 1300;
}

/* Medium Devices, Desktops Only */
@media only screen and (min-width: 992px) {
    ::-webkit-scrollbar {
        width: 12px;
        height: 12px;
        background-color: rgba(0, 0, 0, 0);
    }

    ::-webkit-scrollbar:hover {
        width: 12px;
        height: 12px;
        background-color: rgba(0, 0, 0, 0.06);
    }

    ::-webkit-scrollbar-thumb {
        border: 2px solid transparent;
        box-shadow: inset 0 0 0 20px rgba(0, 0, 0, 0.24);
        border-radius: 20px;
    }

    ::-webkit-scrollbar-thumb:active {
        box-shadow: inset 0 0 0 20px rgba(0, 0, 0, 0.37);
        border-radius: 20px;
    }
}

form label {
    z-index: 99;
}

.height-90 {
    height: 90%;
}

.bg-black-important {
    background-color: #22292F !important;
}

.bg-red-700-important {
    background-color: #D32F2F !important;
}

.bg-orange-important {
    background-color: #FF9800 !important;
}

.bg-yellow-important {
    background-color: #FFEB3B !important;
}

.bg-green-important {
    background-color: #4CAF50 !important;
}

.bg-blue-important {
    background-color: #2196F3 !important;
}

.bg-teal-important {
    background-color: #009688 !important;
}

.bg-gray-A100-important {
    background-color: #D5D5D5 !important;;
}

.bg-indigo-important {
    background-color: #3F51B5 !important;
}

.red-important {
    background: red;
}

@media print
{
    .no-print, .no-print *
    {
        display: none !important;
    }
}

@import 'print.css';

@import 'tables.css';

@import 'react-table.css';

@import 'prism.css';

@import 'tailwind.css';
